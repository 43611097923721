// primary purple color #9e4292
// secondary purple color #723878

.root {
    color: #3a3a3a;
    background-color: #f9f9fb;
    line-height: 1.6;
    font-weight: 400;
}

.contentContainer {
    padding-top: 60px;
}

.sectionPadding {
    padding-left: 16px;
    padding-right: 16px;
}

.button {
    display: inline-block;
    padding: 8px 22px;
    border-radius: 24px;
    cursor: pointer;
    border: 2px solid #fff;
    outline: 0;
    transition: 0.1s ease;
    box-shadow: none;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none;

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.buttonPrimary {
    background-color: #9e4292;
    color: #fff;
    border-color: #9e4292;

    &:hover:not(:disabled) {
        background-color: #723878;
        color: #fff;
        border-color: #723878;
        box-shadow: 0 4px 20px #723878;
    }
}

.buttonSecondary {
    background-color: #fff;
    color: #9e4292;
    border-color: #9e4292;

    &:hover:not(:disabled) {
        color: #723878;
        border-color: #723878;
    }
}

.questionContainer {
    border-radius: 20px;
    max-width: 250px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    pointer-events: auto;
    box-sizing: content-box !important;
    z-index: 1000;
    position: fixed;
    bottom: 60px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 5px 0 20px 5px rgba(0, 0, 0, 0.1);
}

.questionButton {
    max-width: 250px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    border-radius: 20px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    cursor: pointer;
    white-space: nowrap;
    outline: 0;
    border: none;
    background-color: #fff;
    color: #9e4292;
    font-weight: 500;
}

.questionButtonIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
}

.questionText {
    display: none;
}

.divider {
    margin: 48px auto;
    height: 4px;
    max-width: 740px;
    background-image: linear-gradient(to right, transparent, #9e4292, transparent);
    opacity: 0.75;
}

@media only screen and (min-width: 480px) {
    .sectionPadding {
        padding-left: 24px;
        padding-right: 24px;
    }

    .questionContainer {
        bottom: 20px;
        min-width: 120px;
    }

    .questionButton {
        min-width: 120px;
    }

    .questionText {
        display: inline;
    }
}

@media only screen and (min-width: 620px) {
    .sectionPadding {
        padding-left: 48px;
        padding-right: 48px;
    }

    .divider {
        margin: 96px auto;
    }
}

@media only screen and (min-width: 920px) {
}

@media only screen and (min-width: 1450px) {
    .contentContainer {
        padding-top: 80px;
    }

    // .sectionPadding {
    //     padding-left: 48px;
    //     padding-right: 48px;
    // }

    .button {
        padding: 10px 22px;
        font-weight: 600;
        font-size: 16px;
    }
}

// NEWSLETTER
.newsletterWrapper {
    // display: flex;
    // justify-content: center;
    // max-width: 1032px;
    // margin: 0 auto;
    margin-top: 48px !important;
}

.newsletterRoot {
    width: 100%;
    padding: 24px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    border-radius: 8px;

    & > * + * {
        margin-top: 16px;
    }
}

.newsletterForm {
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: 16px;
    }
}

@media only screen and (min-width: 480px) {
    .newsletterForm {
        display: flex;
        flex-direction: row;

        & > * + * {
            margin-top: 0;
            margin-left: 16px;
        }
    }
}

// FONTS //
.heroTitle {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.heroSubtitle {
    font-size: 18px;
    font-weight: 400;
}

.title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
}

.specialOfferText {
    color: #9e4292;
}

.paragraph {
    font-size: 14px;
    font-weight: 300;
}

.headerTitle {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}

.headerSubtitle {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
    .heroTitle {
        font-size: 22px;
    }

    .heroSubtitle {
        font-size: 18px;
    }

    .title {
        font-size: 16px;
    }

    .subtitle {
        font-size: 14px;
    }

    .paragraph {
        font-size: 14px;
    }
}

@media only screen and (min-width: 620px) {
    .title {
        font-size: 18px;
        font-weight: 500;
    }

    .subtitle {
        font-size: 16px;
    }

    .paragraph {
        font-size: 16px;
        font-weight: 300;
    }

    .headerTitle {
        font-size: 14px;
    }

    .headerSubtitle {
        font-size: 11px;
    }
}

@media only screen and (min-width: 920px) {
    .heroTitle {
        font-size: 24px;
    }

    .heroSubtitle {
        font-size: 20px;
    }

    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .subtitle {
        font-size: 18px;
    }

    .paragraph {
        font-size: 16px;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1450px) {
    .heroTitle {
        font-size: 28px;
    }

    .heroSubtitle {
        font-size: 22px;
    }

    .title {
        font-size: 24px;
        font-weight: 500;
    }

    .subtitle {
        font-size: 20px;
    }

    .paragraph {
        font-size: 18px;
    }

    .headerTitle {
        font-size: 16px;
    }

    .headerSubtitle {
        font-size: 13px;
    }
}

// HEADER //
.header {
    width: 100%;
    height: 60px;
    padding: 0 16px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.headerLeft {
    display: flex;
    flex-direction: row;
}

.containerLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
}

.logoLink {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #3a3a3a;

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }
}

.logoImg {
    width: 36px;
    height: 36px;
}

.headerTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    margin-left: 8px;
    user-select: none;
}

.headerNavButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: #9e4292;
    background-color: #fff;
    border: none;
    transition-duration: 0.2s;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }

    & svg {
        fill: #9e4292;
        margin-left: 4px;
    }
}

.headerRight {
    display: none;
}

.headerSocialContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 80px;
    right: 16px;
    padding: 8px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    border-radius: 8px;

    & > * + * {
        margin-left: 8px;
    }
}

.headerSessionBtn {
    display: none;
}

.headerIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: #9e4292;
    background-color: #fff;
    border: none;
    font-size: 18px;
    padding: 8px;
    width: 34px;
    height: 34px;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }

    & svg {
        transition-duration: 0.2s;
        fill: #9e4292;
    }
}

.hamMenu {
    width: 24px;
    height: 24px;
    position: fixed;
    top: 18px;
    right: 16px;
    z-index: 20;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    background-color: #fff;
    color: #9e4292;
    cursor: pointer;
    border: none;

    &:hover {
        color: #723878;
    }

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }
}

.navIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s, transform 0.3s;
}

.hamMenuOpen {
    .navOpenIcon {
        opacity: 0;
        transform: rotate(-45deg);
    }

    .navCloseIcon {
        opacity: 1;
        transform: rotate(0);
    }
}

.navOpenIcon {
    opacity: 1;
}

.navCloseIcon {
    opacity: 0;
    transform: rotate(45deg);
}

.hamNav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    height: 100vh;
    text-align: left;
    padding: 64px 16px;
    position: fixed;
    top: 60px;
    right: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    width: 220px;
    overflow: auto;

    &.hamNavActive {
        transform: translateX(0);
    }

    & ul {
        list-style: none;
    }

    & ul li {
        color: #fff;
        padding: 16px 0;
    }
}

.hamNavButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: #9e4292;
    background-color: #fff;
    border: none;
    border-radius: 20px;
    transition-duration: 0.2s;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;

    &:hover {
        color: #723878;
    }
}

.hamNavPrimaryButton {
    background-color: #9e4292;
    color: #fff;

    &:hover:not(:disabled) {
        background-color: #723878;
        color: #fff;
    }
}

.hamNavLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #9e4292;
    background-color: #fff;
    transition-duration: 0.2s;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 16px;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    & svg {
        fill: #9e4292;
        margin-left: 4px;
    }
}

@media only screen and (min-width: 480px) {
    .header {
        padding: 0 24px;
    }

    .hamMenu {
        right: 24px;
    }

    .hamNav {
        width: 320px;
    }
}

@media only screen and (min-width: 620px) {
    .header {
        padding: 0 36px;
    }

    .hamMenu {
        right: 36px;
    }
}

@media only screen and (min-width: 1060px) {
    .headerTitleContainer {
        margin-left: 12px;
    }

    .headerLink {
        font-size: 16px;
        font-weight: 500;
    }

    .headerRight {
        display: flex;
        flex-direction: row;

        & > * + * {
            margin-left: 24px;
        }
    }

    .headerSessionBtn {
        display: block;
    }

    .hamMenu {
        display: none;
    }

    .hamNav {
        display: none;
    }

    .headerSocialContainer {
        right: 36px;
    }
}

@media only screen and (min-width: 1450px) {
    .header {
        height: 80px;
        padding: 0 48px;
    }

    .logoImg {
        width: 48px;
        height: 48px;
    }

    .headerRight > * + * {
        margin-left: 36px;
    }

    .headerNavButton {
        font-size: 16px;
    }

    .headerIconButton {
        font-size: 22px;
        width: 38px;
        height: 38px;
    }

    .hamNavButton {
        font-size: 16px;
        padding: 12px 16px;
    }

    .hamNavLink {
        font-size: 16px;
        padding: 12px 16px;
    }

    .headerSocialContainer {
        top: 100px;
        right: 48px;
    }
}

// COMMON //
.callToActionContainer {
    & > * + * {
        margin-top: 24px;
    }
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.onlyDesktop {
    display: none;
}

@media only screen and (min-width: 1060px) {
    .onlyMobile {
        display: none;
    }

    .onlyDesktop {
        display: block;
    }
}

// HERO //
.sectionHero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50vh;
    min-height: 680px;
    background: bottom center / cover no-repeat
        url('https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-images/new_hero_image_mobile_2x.webp');
}

.sectionHeroWrapper {
    color: #fff;
    width: 100%;
    height: 100%;
    padding: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background-color: #162e3a;
    }
}

.sectionHeroContainer {
    position: relative;

    & > * + * {
        padding-top: 36px;
    }
}

.sectionHeroTextBlock {
    max-width: 920px;
}

.singleVideoBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

.singleVideoFrame {
    width: 304px;
    height: 171px;
}

.heroActionBlock {
    & a {
        color: #fff;
    }

    & > * + * {
        padding-top: 16px;
    }
}

@media only screen and (min-width: 480px) {
    .sectionHeroWrapper {
        padding: 16px 24px;
    }

    .singleVideoFrame {
        width: 464px;
        height: 261px;
    }
}

@media only screen and (min-width: 620px) {
    .sectionHero {
        min-height: 760px;
        background: bottom center / cover no-repeat
            url('https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-images/new_hero_image_mobile_2x.webp');
    }

    .sectionHeroWrapper {
        padding: 24px 32px;
    }

    .singleVideoFrame {
        width: 560px;
        height: 315px;
    }
}

@media only screen and (min-width: 920px) {
    .sectionHero {
        height: 65vh;
    }

    .sectionHeroWrapper {
        padding: 24px 48px;
    }
}

@media only screen and (min-width: 1200px) {
    .sectionHero {
        height: 80vh;
        background: bottom center / cover no-repeat
            url('https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-images/new_hero_image_1x.webp');
    }
}

@media only screen and (min-width: 1450px) {
}

@media only screen and (min-width: 1920px) {
    .sectionHero {
        background: bottom right / cover no-repeat
            url('https://coaching-web-sk.s3.us-west-1.amazonaws.com/website-images/new_hero_image_2x.webp');
    }
}

// REVIEWS //
.bodySocialContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;

    & > * + * {
        margin-left: 8px;
    }
}

.bodyIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    color: #9e4292;
    border: none;
    font-size: 18px;
    padding: 8px;
    width: 34px;
    height: 34px;

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }

    & svg {
        transition-duration: 0.2s;
        fill: #9e4292;
    }

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }
}

@media only screen and (min-width: 980px) {
    .bodyIconButton {
        font-size: 22px;
        width: 38px;
        height: 38px;
    }
}

// GUIDE //
.bookContainer {
    & > * + * {
        margin-top: 12px;
    }
}

.bookImageAndDescriptionContainer {
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-left: 0;
        margin-top: 24px;
    }
}

.bookImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookImageBox {
    display: flex;
    justify-content: center;
}

.bookImage {
    width: 160px;
}

.bookTextContainer {
    & > * + * {
        margin-top: 12px;
    }
}

@media only screen and (min-width: 920px) {
    .bookImageAndDescriptionContainer {
        flex-direction: row;

        & > * + * {
            margin-left: 24px;
            margin-top: 0;
        }
    }

    .bookImage {
        width: 240px;
    }

    .bookContainer {
        & > * + * {
            margin-top: 24px;
        }
    }

    .bookTextContainer {
        & > * + * {
            margin-top: 24px;
        }
    }
}

// ABOUT //
.sectionBio {
    // max-width: 860px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;

    & > * + * {
        margin-top: 24px;
    }
}

.bioWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
        margin-top: 24px;
    }
}

.bioContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * + * {
        margin-top: 8px;
    }

    & > div {
        & > * + * {
            margin-top: 8px;
        }
    }
}

.bioBarkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.bioTwoWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px !important;

    & > * + * {
        margin-top: 24px;
    }
}

.bioTwoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > * + * {
        margin-top: 24px;
    }
}

.bioList {
    padding-left: 32px;
}

.photoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.photoImg {
    width: 100%;
    max-width: 240px;
}

.nameAndHeartBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heartImgBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heartImg {
    margin-left: 8px;
}

.signatureImg {
    width: 140px;
}

.credsItemsContainer {
    max-width: 1032px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
    margin-top: 12px;
    gap: 24px 12px;
}

.credsItemContainer {
    background-color: #fff;
    padding: 4px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    transition: box-shadow 0.4s ease 0s;
    transform: translateX(0px);
    user-select: none;

    &:hover {
        box-shadow: 0 6px 24px -1px rgba(0, 0, 0, 0.1);
    }
}

@media only screen and (min-width: 480px) {
    .signatureImg {
        width: 100%;
    }

    .photoImg {
        max-width: 320px;
    }
}

@media only screen and (min-width: 620px) {
}

@media only screen and (min-width: 920px) {
    .sectionBio {
        max-width: 1032px;
    }

    .bioWrapper {
        flex-direction: row;

        & > * + * {
            margin-top: 0;
        }
    }

    .bioContainer {
        width: 60%;
        margin-left: 32px;
    }

    .photoContainer {
        width: 40%;
    }

    .photoImg {
        max-width: 380px;
    }
}

@media only screen and (min-width: 1450px) {
    .photoImg {
        // width: 320px;
        // height: 320px;
    }
}

.sectionVideos {
    width: 100%;
    min-height: 200px;

    & > * + * {
        margin-top: 48px;
    }
}

.videosWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * + * {
        margin-top: 48px;
    }
}

.videosBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}

.videosFrame {
    width: 288px;
    height: 162px;
}

@media only screen and (min-width: 480px) {
    .videosFrame {
        width: 464px;
        height: 261px;
    }

    .videosWrapper {
        & > * + * {
            margin-top: 60px;
        }
    }
}

@media only screen and (min-width: 620px) {
    .videosFrame {
        width: 560px;
        height: 315px;
    }

    .videosWrapper {
        & > * + * {
            margin-top: 92px;
        }
    }
}

// COACHING //
.guideImageAndDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
        margin-left: 0;
        margin-top: 24px;
    }
}

.guideDescriptionContainer {
    width: 100%;
    & > * + * {
        margin-top: 12px;
    }
}

.guideImageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guideImageBox {
    display: flex;
    justify-content: center;
}

.guideImage {
    width: 100%;
    max-width: 240px;
    // width: 160px;
}

.guideTextContainer {
    & > * + * {
        margin-top: 12px;
    }
}

@media only screen and (min-width: 480px) {
    .guideImage {
        max-width: 320px;
    }
}

@media only screen and (min-width: 920px) {
    .guideImageAndDescriptionContainer {
        flex-direction: row;

        & > * + * {
            margin-left: 24px;
            margin-top: 0;
        }
    }

    .guideDescriptionContainer {
        width: 60%;

        & > * + * {
            margin-top: 24px;
        }
    }

    .guideImageContainer {
        width: 40%;
    }

    .guideTextContainer {
        & > * + * {
            margin-top: 24px;
        }
    }

    .guideImage {
        max-width: 380px;
    }
}

// BENEFITS //
.sectionInfo {
    width: 100%;
    min-height: 400px;

    & > * + * {
        margin-top: 48px;
    }
}

.coachingPadding {
    & > * + * {
        margin-top: 60px;
    }
}

.benefitsWrapper {
    & > h3 {
        text-align: center;
    }
}

.benefitsContainer {
    max-width: 280px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.benefitsList {
    list-style: none;

    & > * + * {
        margin-top: 36px;
    }
}

.benefitsListItem {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    transition: box-shadow 0.4s ease 0s;
    transform: translateX(0px);
    user-select: none;

    &:hover {
        box-shadow: 0 6px 24px -1px rgba(0, 0, 0, 0.1);

        & .benefitsItemImg {
            opacity: 1;
        }
    }
}

.benefitsItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * + * {
        margin-top: 16px;
    }
}

.benefitsItemImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.benefitsItemImg {
    border-radius: 50%;
    opacity: 0.75;
    transition-duration: 0.4s;
    width: 160px;
    height: 160px;
}

.benefitsItemBody {
    & > * + * {
        margin-top: 4px;
    }
}

.distinctionsWrapper {
    & > * + * {
        margin-top: 48px;
    }
}

.distinctionsBody {
    padding-left: 16px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > * + * {
        margin-top: 16px;
    }
}

.distinctionsList {
    margin-top: 8px;
    text-align: left;

    & b {
        // color: #9e4292;
        font-weight: 500;
    }

    & > * + * {
        margin-top: 8px;
    }

    & li::marker {
        // color: #9e4292;
    }
}

.benefitsWrapper > h3 {
    text-align: center;
}

@media only screen and (min-width: 480px) {
    .distinctionsBody {
        padding-left: 24px;
    }
}

@media only screen and (min-width: 620px) {
    .benefitsContainer {
        max-width: 680px;
    }

    .benefitsItem {
        flex-direction: row;
    }

    .benefitsItemBody {
        margin-top: 0;
        margin-left: 36px;
    }

    .distinctionsBody {
        padding-left: 36px;
    }

    .distinctionsList {
        & > * + * {
            margin-top: 12px;
        }
    }
}

@media only screen and (min-width: 920px) {
    .benefitsContainer {
        max-width: 1032px;
    }

    .benefitsItemImg {
        width: 180px;
        height: 180px;
    }

    .distinctionsBody {
        padding-left: 48px;
    }

    .distinctionsList {
        margin-left: 36px;
    }
}

@media only screen and (min-width: 1450px) {
}

// EVENTS //
.eventsWrapper {
    margin-top: 24px;
    text-align: center;

    & > * + * {
        margin-top: 24px;
    }
}

// .eventsBody {
//     margin-left: auto;
//     margin-right: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: left;

//     & > * + * {
//         margin-top: 36px;
//     }
// }

.eventsBody {
    max-width: 1032px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
    gap: 36px 24px;
    text-align: left;

    & > * + * {
        margin-top: 0;
    }
}

.event {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 280px;
    width: 100%;
    padding: 24px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.4s ease 0s;
    transform: translateX(0px);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;

    & > * + * {
        margin-top: 16px;
    }

    &:hover {
        box-shadow: 0 6px 24px -1px rgba(0, 0, 0, 0.1);
    }
}

.eventImageContainer {
    width: 140px;
    height: 70px;
}

.eventTextContainer {
    // min-height: 140px;
    height: 100%;
}

.eventImage {
    width: 100%;
    height: 100%;
}
.eventDescription {
    height: 120px;
    margin-top: 4px;
}

.eventButtonContainer {
    display: flex;
}

@media only screen and (min-width: 480px) {
    .event {
        width: 400px;
    }
}

@media only screen and (min-width: 620px) {
    .eventImageContainer {
        width: 180px;
        height: 90px;
    }

    .event {
        justify-content: center;
    }
}

// @media only screen and (min-width: 920px) {
//     .eventsBody {
//         max-width: 1032px;
//         justify-content: space-between;
//         flex-direction: row;

//         & > * + * {
//             margin-left: 24px;
//             margin-top: 0;
//         }
//     }
// }

@media only screen and (min-width: 1450px) {
    .eventTextContainer {
        // min-height: 180px;
    }
}

// STORIES //
.sectionInfo {
    width: 100%;
    min-height: 400px;

    & > * + * {
        margin-top: 48px;
    }
}

.storiesContainer {
    max-width: 280px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.storiesList {
    list-style: none;

    & > * + * {
        margin-top: 36px;
    }
}

.storiesListItem {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    transition: box-shadow 0.4s ease 0s;
    transform: translateX(0px);
}

.storiesItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > * + * {
        margin-top: 16px;
    }
}

.storiesItemImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.storiesItemImg {
    border-radius: 8px;
    // opacity: 0.75;
    transition-duration: 0.4s;
    width: 160px;
    height: 160px;
}

.storiesItemBody {
    & > * + * {
        margin-top: 4px;
    }
}

@media only screen and (min-width: 480px) {
}

@media only screen and (min-width: 620px) {
    .storiesContainer {
        max-width: 680px;
    }

    .storiesItem {
        flex-direction: row;

        & > * + * {
            margin-top: 0;
        }
    }

    .storiesItemBody {
        margin-top: 0;
        margin-right: 36px;
    }
}

@media only screen and (min-width: 920px) {
    .storiesContainer {
        max-width: 1032px;
    }

    .storiesItemImg {
        width: 180px;
        height: 180px;
    }
}

@media only screen and (min-width: 1450px) {
}

// PACKAGES //
.sectionPackages {
    width: 100%;
    min-height: 400px;
}

.descriptionWrapper {
    max-width: 1032px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // text-align: center;

    & > * + * {
        margin-top: 8px;
    }
}

.descriptionList {
    text-align: left;
    width: 100%;

    & ul {
        list-style: none;

        & > * + * {
            margin-top: 8px;
        }
    }
}

.checkMark {
    margin-right: 12px;
    width: 14px;
    height: 14px;
    fill: #9e4292;
}

.contractLink {
    text-decoration: none;
    font-weight: 500;
    color: #9e4292;
    transition-duration: 0.2s;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    & svg {
        margin-left: 4px;
    }
}

.contractLink2 {
    text-decoration: none;
    font-weight: 500;
    color: #9e4292;
    transition-duration: 0.2s;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    & svg {
        margin-left: 4px;
    }
}

.contractLink3 {
    text-decoration: none;
    font-weight: 500;
    color: #9e4292;
    transition-duration: 0.2s;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    & svg {
        margin-left: 4px;
    }
}

.contractLink4 {
    text-decoration: none;
    font-weight: 500;
    color: #9e4292;
    transition-duration: 0.2s;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }

    & svg {
        margin-left: 4px;
    }
}

.phoneCallButtonLink {
    // text-decoration: none;
    font-weight: 500;
    color: #9e4292;
    transition-duration: 0.2s;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        color: #723878;

        svg {
            fill: #723878;
        }
    }
}

.packagesWrapper {
    margin-top: 24px;
    // text-align: center;

    & > * + * {
        margin-top: 24px;
    }
}

.packagesRate {
    & > * + * {
        margin-top: 24px;
    }

    & select {
        margin-left: 8px;
    }
}

.rateDescribtion {
    & > * + * {
        margin-top: 12px;
    }
}

.slidingScaleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.slidingScaleLabel {
    margin-bottom: 12px;
}

.slidingScaleSlider {
    width: 100%;
    margin: 12px 0;
}

.sliderTrack {
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    height: 4px;
    background: lightgray;
    z-index: 1;
}

.rateValues {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.packagesBody {
    max-width: 1032px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
    gap: 36px 24px;
    text-align: left;

    & > * + * {
        margin-top: 0;
    }
}

.package {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 280px;
    width: 100%;
    min-height: 140px;
    padding: 24px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.4s ease 0s;
    transform: translateX(0px);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    border-top: 8px solid #9e4292;

    & > * + * {
        margin-top: 16px;
    }

    &:hover {
        box-shadow: 0 6px 24px -1px rgba(0, 0, 0, 0.1);
    }
}

.packageImageContainer {
    width: 140px;
    height: 140px;
}

.packageTextContainer {
    // min-height: 160px;
    height: 100%;
}

.cardTitle {
    font-size: 14px;
    font-weight: 500;
}

.cardText {
    font-size: 14px;
    font-weight: 300;
}

.packageImage {
    width: 100%;
    height: 100%;
}
.packageDescription {
    height: 120px;
    margin-top: 4px;
}

.packageButtonContainer {
    display: flex;
}

@media only screen and (min-width: 480px) {
    .descriptionWrapper {
        margin-top: 8px;
    }

    .package {
        width: 400px;
    }
}

@media only screen and (min-width: 620px) {
    .checkMark {
        width: 16px;
        height: 16px;
    }

    .descriptionWrapper {
        margin-top: 16px;
    }

    .packageImageContainer {
        width: 180px;
        height: 180px;
    }

    .descriptionList {
        margin-top: 16px;

        & ul {
            & > * + * {
                margin-top: 12px;
            }
        }
    }

    .package {
        justify-content: center;
    }

    .cardTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .cardText {
        font-size: 16px;
        font-weight: 300;
    }
}

@media only screen and (min-width: 920px) {
    .cardTitle {
        font-size: 18px;
        font-weight: 500;
    }

    .cardText {
        font-size: 16px;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1450px) {
    .packageTextContainer {
        // min-height: 180px;
    }
}

// BOOK SESSION //
.sectionBookSession {
    width: 100%;
    min-height: 400px;
}

// .sectionCalendar {
//     max-width: 1140px;
// }

.bookSessionContainer {
    // max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    & > * + * {
        margin-top: 16px;
    }
}

.bookSessionBody {
    margin-top: 24px;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    // min-width: 320px;
    // width: 100%;
    // min-height: 700px;
    // padding: 0;
    // margin: 0;
}

@media only screen and (min-width: 480px) {
    .bookSessionBody {
        padding: 0 8px;
    }
}

@media only screen and (min-width: 620px) {
    .bookSessionBody {
        margin-top: 36px;
        padding: 0 24px;
        max-width: 1080px;
    }
}

@media only screen and (min-width: 920px) {
    .bookSessionContainer {
        max-width: 860px;
    }

    .bookSessionBody {
        padding: 0 48px;
    }
}

@media only screen and (min-width: 1450px) {
    .bookSessionContainer {
        max-width: 1032px;
    }
}

// FOOTER //
.footer {
    width: 100%;
    position: relative;
    padding: 10px 16px;
    margin-top: 48px;
    background-color: #333333;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.copyright {
    color: #e6e6e6;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
}

.footerSocialLinks {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 8px;

    & > * + * {
        margin-left: 0;
        margin-top: 8px;
    }
}

.footerSocialLink {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: #ccc;

    & > svg {
        margin-right: 6px;
    }

    &:hover {
        color: #9e4292;

        svg {
            fill: #9e4292;
        }
    }

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }

    & svg {
        transition-duration: 0.2s;
        fill: #e6e6e6;
    }
}

@media only screen and (min-width: 480px) {
    .footer {
        padding: 10px 24px;
    }
}

@media only screen and (min-width: 620px) {
    .footer {
        padding: 10px 36px;
    }
}

@media only screen and (min-width: 580px) {
    .footerSocialLinks {
        flex-direction: row;
        margin-top: 0;

        & > * + * {
            margin-left: 16px;
            margin-top: 0;
        }
    }
}

@media only screen and (min-width: 1450px) {
    .footer {
        padding: 10px 48px;
    }
}
