.root {
    width: 100%;
    display: inline-block;
    padding: 10px 22px;
    border-radius: 24px;
    cursor: pointer;
    border: 2px solid #fff;
    outline: 0;
    transition: 0.1s ease;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }

    &:focus {
        outline: 1px solid #723878;
        outline-offset: 2px;
    }
}

.primary {
    background-color: #9e4292;
    color: #fff;
    border-color: #9e4292;

    &:hover:not(:disabled) {
        background-color: #723878;
        color: #fff;
        border-color: #723878;
        box-shadow: 0 4px 20px #723878;
    }
}

.secondary {
    background-color: #fff;
    color: #9e4292;
    border-color: #9e4292;

    &:hover:not(:disabled) {
        color: #723878;
        border-color: #723878;
    }
}

.icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    animation: rotation 1s infinite linear;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
