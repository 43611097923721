@import-normalize;

body,
html {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    min-width: 320px;
    /* overscroll-behavior: none; */
}

body {
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
}

.ReactModal__Overlay {
    z-index: 9000;
}

.no-scroll {
    overflow: hidden;
}
