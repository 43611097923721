.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
}

.hideLabel {
    display: none;
}

.textfield {
    padding: 4px 16px;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 8px;
    outline: none;
    border: 1px solid #a39db3;
    margin: 0;
    margin-top: 8px;
    display: block;
    width: 100%;
    height: 46px;
    color: #4d4d4d;

    &.hideLaberText {
        margin-top: 0;
    }

    &::placeholder {
        // color: #a39db3;
        // font-weight: 200;
    }
}
